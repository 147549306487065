import React from 'react';
import { IForm } from '../globals/types';
import { TFunction } from 'i18next';

const form_find_architect = (t: TFunction<any>) => { return {
    target: '/3/api/find_architect.php',
    screens: [
        {
            title: "¿Qué servicio necesitas?",
            subtitle: <>{t("Selecciona tu servicio")}</>,
            items: [
                {
                    title: <>1. {t('Rehab. Energética Next Gen.')}</>,
                    type: "radio",
                    fieldName: "rehab_next_gen",
                    required: false,
                    service_label: t('Rehab. Energética Next Gen.'),
                    options: [
                        {
                            title: <>1.1.-
                                <b>{t('AGENTE REHABILITADOR (realización de los trámites y gestiones necesarios para la solicitud de subvenciones)')}</b>
                                &nbsp;{t('NO HACE EL PROYECTO')}
                            </>,
                            optionKey: "rehabilitador",
                            description: <>{t('Tramitación de la Subvención')}</>
                        },
                        {
                            title: <>1.2.-
                                <b>{t('PROYECTO Y SUBVENCIÓN EDIFICIOS')}<br/>
                                {t('Proyectos y obras de rehabilitación energética a nivel EDIFICIO')}</b><br/>
                                {t('Principalmente')} <b>{t('Programa 3')}</b> {t('de los Next Generation')}
                            </>,
                            optionKey: "edificio",
                            description: <b>{t('actuaciones en fachadas, protecciones solares y cubiertas, instalaciones del edificio y energias renovables')}</b>
                        },
                        {
                            title: <>1.3.-
                                <b>{t('PROYECTO Y SUBVENCIÓN VIVIENDAS')}<br/>
                                {t('Proyectos y obras de rehabilitación energética a nivel VIVIENDA')}</b><br/>
                                {t('Principalmente')} <b>{t('Programa 4')}</b> {t('de los Next Generation')}
                            </>,
                            optionKey: "vivienda",
                            description: <b>{t('Rehabilitación energética pisos.')}<br/>
                            {t('Cambio ventanas.')}<br/>
                            {t('Calderas, instalaciones interiores.')}<br/>
                            {t('Climatización y refrigeración.')}</b>
                        },
                    ]
                },

                { 
                    title: <>2. {t('Proyectos y Dirección de obra')}</>,
                    type: "dropdown",
                    fieldName: "proyectos_direccion_obra",
                    required: false,
                    service_label: t('Proyectos y Dirección de obra'),
                    options: [
                        { optionKey: "edificacion", title: 'Proyecto de edificación' },
                        { optionKey: "vivienda", title: 'Vivienda' },
                        { optionKey: "piscina_jardin", title: 'Piscina o jardín' },
                        { optionKey: "legalizaciones", title: 'Legalizaciones' },
                        { optionKey: "cambio_uso", title: 'Proyecto Cambio de uso' },
                        { optionKey: "reformas", title: 'Proyecto reformas' },
                        { optionKey: "accesibilidad", title: 'Accesibilidad' }
                    ]
                },

                { 
                    title: <>3. {t('Rehabilitación y mant. edificios')}</>,
                    type: "dropdown",
                    fieldName: "rehab_mant_edificios",
                    required: false,
                    service_label: t('Rehabilitación y mant. edificios'),
                    options: [
                        { optionKey: "vivienda", title: 'Rehabilitación de viviendas' },
                        { optionKey: "fachadas", title: 'Rehabilitación de fachadas' },
                        { optionKey: "patologias", title: 'Patologías y grietas' },
                        { optionKey: "humedades", title: 'Humedades' },
                        { optionKey: "ascensor", title: 'Colocación de un ascensor' },
                        { optionKey: "solidez", title: 'Certificado de solidez' },
                        { optionKey: "aluminosis", title: 'Aluminosis' },
                    ]
                },

                {
                    title: <>4. {t('ITE, Inspección Técnica de Edificio')}</>,
                    type: 'boolean',
                    fieldName: 'ite',
                    service_label: 'ITE',
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>5. {t('Patrimonio')}</>,
                    type: 'boolean',
                    fieldName: 'patrimonio',
                    service_label: t('Patrimonio'),
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>6. {t('Paisajismo')}</>,
                    type: 'boolean',
                    fieldName: 'paisajismo',
                    service_label: t('Paisajismo'),
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>7. {t('Parcelaciones y Urbanismo')}</>,
                    type: 'boolean',
                    fieldName: 'parcelaciones',
                    service_label: t('Parcelaciones y Urbanismo'),
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>8. {t('Interiorismo')}</>,
                    type: 'boolean',
                    fieldName: 'interiorismo',
                    service_label: t('Interiorismo'),
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>9. {t('Mediación')}</>,
                    type: 'boolean',
                    fieldName: 'mediacion',
                    service_label: t('Mediación'),
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>10. {t('Certificado energético')}</>,
                    type: 'boolean',
                    fieldName: 'cert_energetico',
                    service_label: t('Certificado energético'),
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>11. {t('Cédula de habitabilidad')}</>,
                    type: 'boolean',
                    fieldName: 'cedula_habit',
                    service_label: t('Cédula de habitabilidad'),
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>12. {t('Licencia de actividades')}</>,
                    type: 'boolean',
                    fieldName: 'lic_actividades',
                    service_label: t('Licencia de actividades'),
                    required: false,
                    defaultValue: false,
                    description: <>{t('Licencia de actividades de un local')}<br/>
                        {t('Licencia de rótulo de local')}</>
                },

                {
                    title: <>13. {t('Informes y certificados')}</>,
                    type: 'boolean',
                    fieldName: 'informes_certificados',
                    service_label: t('Informes y certificados'),
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>14. {t('Rutas arquitectónicas')}</>,
                    type: 'boolean',
                    fieldName: 'rutas_arq',
                    service_label: t('Rutas arquitectónicas'),
                    required: false,
                    defaultValue: false
                },

                {
                    title: <>15. {t('Talleres de arquitectura en escuelas')}</>,
                    type: 'boolean',
                    fieldName: 'talleres_escuelas',
                    service_label: t('Talleres de arquitectura en escuelas'),
                    required: false,
                    defaultValue: false
                },
            ]
        },
        {
            title: "Datos del ciudadano",
            items: [
                {
                    title: "Emplazamiento de la obra",
                    type: "section",
                    items: [

                        {
                            title: 'Código postal',
                            type: 'zip_code',
                            fieldName: 'zip_code',
                            required: true,
                            defaultValue: false,
                            remember: true
                        }
        
                    ]
                },
                {
                    title: "Nombre y Apellido",
                    type: "single_line_text",
                    fieldName: "nombre_apellido",
                    required: true,
                    remember: true,
                },
                {
                    title: "Correo electrónico",
                    type: "email",
                    fieldName: "email",
                    required: true,
                    remember: true,
                },
                {
                    title: "Teléfono (NO OBLIGATORIO)",
                    type: "phone",
                    fieldName: "phone",
                    required: false,
                },
                {
                    title: "",
                    type: "lopd",
                    fieldName: "lopd",
                    required: true,
                }

            ]
        },

    ]
} as IForm;
}

export default form_find_architect;