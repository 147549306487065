import React from 'react';
import { IFormItem_Boolean, IFormItem_Dropdown, IFormItem_Radio } from '../../globals/types';
import { useField } from 'formik';
import Select from 'react-select';
import FormCheck from 'react-bootstrap/FormCheck';
import { useTranslation } from 'react-i18next';

type MyProps = {
    config: IFormItem_Boolean
};

export default function FormikItem_Boolean({
    config
}: MyProps ) {
    const {t} = useTranslation();
    const [field, meta, helpers] = useField(config.fieldName);

    return <div className='formik-row'>
        <div className="column1">{typeof config.title == 'string' ? t(config.title) : config.title}</div>
        <div className='column2 d-flex'>
            <FormCheck>
                                    <FormCheck.Input type="checkbox" name={config.fieldName}
                                    style={{width: '30px', height: '30px'}}
                                    checked={field.value || false}
                                    onChange={(e)=>{
                                        helpers.setValue(e.target.checked);
                                    }}
                                    />
            </FormCheck>
            {config.description && <div className='mx-3'>{typeof config.description == 'string' ? t(config.description) : config.description}</div>}
        </div>
    </div>;
}