// Using a signature, get with ReactQuery some JSON from process.env.BACKEND + /api/feed
import { useQuery } from 'react-query';

export type IArquitectTraining = {
    inicio: string,
    fin: string|null,
    titulo: string,
    centro: string|null,
    descripcion: string|null
}

export type IArquitectCompany = {
    inicio: string,
    fin: string|null,
    empresa: string,
    web: string|null,
    funciones: string|null
}

export type IArquitectData = {
    num_colegiado: string,
    nombre: string,
    apellidos: string,
    delegacion: string,
    ciudad: string|null,
    zip_code: string|null,
    phone: string,
    email: string,
    formaciones: IArquitectTraining[],
    empresas: IArquitectCompany[]
}

export type IResultsFeedReply = {
    error: string|null,
    count: number,
    rows: IArquitectData[],
    busqueda: {
        cedula_habit: boolean,
        cert_energetico: boolean,
        informes_certificados: boolean,
        ite: boolean,
        lic_actividades: boolean,
        projectos_direccion_obra: string,
        rehab_mant_edificios: string,
        rehab_next_gen: string
    },
    request_zip_data: {
        comarca: string,
        // ... hay más
    }
}

export default function useResultsFeed( signature: string, page: number, per_page: number, sort_field: string|null, sort_direction: 'asc'|'desc' ) {
    const url = (process.env.BACKEND || '') + '/3/api/feed.php?signature=' + signature + '&page=' + page + '&per_page=' + per_page
        + '&sort_field=' + (sort_field || '') + '&sort_direction=' + sort_direction;
    const query = useQuery<IResultsFeedReply,Error>( url, async () => {
        const reply = await fetch( url );
        return reply.json();
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchInterval: false,
        staleTime: 1000 * 60 * 60 * 2, // 2 hours
    });
    return query;
}

export async function getFullResults( signature: string ) {
    const url = (process.env.BACKEND || '') + '/3/api/feed.php?signature=' + signature + '&page=' + 1 + '&per_page=10000';
    const reply = await fetch( url );
    return await reply.json() as IResultsFeedReply;
}