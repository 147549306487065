import {useQuery} from 'react-query';

export type IZipCodeData = null | {
    zip_code: string,
    cod_municipio: string,
    cod_comarca: string,
    municipio: string,
    comarca: string
};

export default function useZipCodeData(zipcode: string) {
    return useQuery<IZipCodeData,Error>('zipcode/'+zipcode, async () => {
        if( zipcode.trim().length < 3 ) {
            return null;
        }
        const response = await fetch((process.env.BACKEND || '') + `/zipcodes/buscar.php?zipcode=${zipcode.trim()}`);
        if (!response.ok) {
            throw new Error('Network response');
        }
        const data = await response.json();
        return data as IZipCodeData;
    }, {
        staleTime: 1000 * 60 * 60 * 24 * 30, // 30 days
    });
}