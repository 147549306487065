import React, { useEffect } from 'react';
import { IFormItem_Boolean, IFormItem_Dropdown, IFormItem_PostalCode, IFormItem_Radio } from '../../globals/types';
import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import useZipCodeData, { IZipCodeData } from '../../hooks/useZipCodeData';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

type MyProps = {
    config: IFormItem_PostalCode,
};

export default function FormikItem_ZipCode({
    config
}: MyProps ) {
    const {t} = useTranslation();
    const [field, meta, helpers] = useField(config.fieldName);
    const reply = useZipCodeData(field.value||'');

    useEffect(()=>{
        if( !reply.isLoading ) {
            if( reply.error && !meta.error ) {
                helpers.setError(t('Error'));
            } else if( !reply.data && !meta.error ) {
                helpers.setError(t('No encontrado'));
            }
        }
    }, [reply]);

    return <>
        <div className='formik-row'>
            <div className="column1">
                {typeof config.title == 'string' ? t(config.title) : config.title}
                {config.required && <span className='text-danger'> *</span>}
            </div>
            <div className='d-flex'>
                <Form.Control 
                    className='zip_code'
                    minLength={5} maxLength={5} value={field.value||''} 
                    isInvalid={meta.touched && !!meta.error}
                    onBlur={field.onBlur}
                    name={config.fieldName}
                    required={config.required}
                    onChange={(e)=>{
                        helpers.setValue(e.target.value);
                        helpers.setTouched(true);
                    }}
                    />
                {meta.touched && meta.error && <Form.Control.Feedback className='ml-3' type='invalid'>{meta.error}</Form.Control.Feedback>}
            </div>
        </div>
        <div className='formik-row'>
            <div className="column1">{t('Municipio')}</div>
            <div className='column2 d-flex'>
                <FCComarcaMunicipio reply={reply} show='municipio' />
            </div>
        </div>
        <div className='formik-row'>
            <div className="column1">{t('Comarca')}</div>
            <div className='column2 d-flex'>
                <FCComarcaMunicipio reply={reply} show='comarca' />
            </div>
        </div>
    
    </>;
}

function FCComarcaMunicipio({
    reply,
    show
}: { 
    reply: UseQueryResult<IZipCodeData,Error>,
    show: 'municipio' | 'comarca'
 }) {
    const {t} = useTranslation();
    if(reply.isLoading) {
        return <Form.Control disabled value={t('Cargando...')} />;
    } 
    if( reply.error) {
        return <Form.Control disabled value={t('Error')} />;
    }
    if( !reply.data ) {
        return <Form.Control disabled isInvalid value={t('No encontrado')} />;
    }
    return <Form.Control disabled value={reply.data[show]} />
}