import React from 'react';
import { IFormItem_Section } from '../../globals/types';
import { Card } from 'react-bootstrap';
import FormikItem from './FormikItem';
import { useTranslation } from 'react-i18next';

type MyProps = {
    config: IFormItem_Section
};

export default function FormikItem_Section({
    config
}: MyProps ) {
    const {t} = useTranslation();
    return <Card className='mb-3'>
        <Card.Header>{typeof config.title == 'string' ? t(config.title) : config.title }</Card.Header>
        <Card.Body>
            {config.items.map((item,i) => {
                return <FormikItem item={item} key={i} />;
            })}
        </Card.Body>
    </Card>
}