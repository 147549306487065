import React from 'react';

import Header from './Header';
import Footer from './Footer';

export default function Layout({
    children
}: { children: JSX.Element[]|JSX.Element }) {

    return (<>
        <Header />
        <div className="container-fluid p-3 mb-4" id="main">
            <div className="w-100 content-page content-page-0" style={{minHeight: '80vh'}}>
                {children}
            </div>
        </div>
        <Footer/></>);
}