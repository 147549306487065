import React from 'react';
import Layout from '../layout/Layout';
import DynamicForm from '../components/dynamic_form/DynamicForm';
import form_find_architect from '../config/form_find_architect';
import i18next from '../i18n';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TablaResultados from '../components/tabla_resultados/TablaResultados';
import ScrollToTop from '../components/scroll_to_top/ScrollToTop';
import { useTranslation } from 'react-i18next';
i18next;

function Dev() {
	return <div>HOLAAA</div>;
}

export default function App() : JSX.Element {
	const {t} = useTranslation();

	const cfg = form_find_architect(t);
	return (<Layout>
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path='/3/front/resultados' Component={TablaResultados} />
				<Route path='/3/priv/resultados' Component={TablaResultados} />
				<Route path='*' Component={() => <DynamicForm config={cfg} />} />
			</Routes>
		</BrowserRouter>
	</Layout>);
}


