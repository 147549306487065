import React from 'react';
import { IFormItem_Radio } from '../../globals/types';
import { Form, useField } from 'formik';
import FormCheck from 'react-bootstrap/FormCheck';

type MyProps = {
    config: IFormItem_Radio
};

export default function FormikItem_Radio({
    config
}: MyProps ) {
    const [field, meta, helpers] = useField(config.fieldName);

    return <div className='formik-radio'>
        <div className="column1">{config.title}</div>
        {config.options.map((option,i) => {
            return <React.Fragment key={i}>
                <div className="column2" key={i+'_a'} onClick={() => {
                        if( option.optionKey !== field.value) {
                            helpers.setValue(option.optionKey);
                        } else {
                            // Si el campo no es requerido, tal vez el usuario quiere deseleccionar la opción
                            if( !config.required ) {
                                helpers.setValue(null);
                            }
                        }
                    }}>
                    <div className="radio">
                        <FormCheck>
                            <FormCheck.Input type="checkbox" name={config.fieldName}
                                    style={{width: '30px', height: '30px'}}
                                    checked={field.value == option.optionKey} 
                                    onChange={(e)=>{}}
                                    />
                        </FormCheck> 
                    </div>
                    <div className='radio-title'>{option.title}</div>
                </div>
                <div className="column3" key={i+'_b'}>{option.description}</div>
            </React.Fragment>
        })}
    </div>;
}