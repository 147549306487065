import React, { useMemo } from 'react';
import { IForm } from '../../globals/types';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import FormikScreen from './FormikScreen';
import './DynamicForm.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';
import FormikSubmitButton from './FormikSubmitButton';
import DynamicFormSaver from './DynamicFormSaver';
import useLocalStorage from '../../hooks/useLocalStorage';
import sendForm from '../../api/sendForm';

type MyProps = {
    config: IForm
}

export default function DynamicForm({
    config
}: MyProps) {
    const {t} = useTranslation();
    const location = useLocation();
    const [submit_error, setSubmitError] = React.useState<string|false>(false);

    const [state_screen_index, setCurrentScreenIndex] = React.useState(0);
    const current_screen_index = (location.state && location.state.current_screen_index !== undefined) 
        ? location.state.current_screen_index 
        : state_screen_index;
    const navigate = useNavigate();
    const screen = config.screens[current_screen_index];

    const hasPrevious = current_screen_index > 0;
    const hasNext = current_screen_index < config.screens.length - 1;

    const [previous_values] = useLocalStorage<Record<string,any>>('dynamic_form', {});

    const initialValues = useMemo(() => {
        const values : Record<string,any> = {};
        config.screens.forEach(screen => {
            screen.items.forEach(field => {
                if( field.type == 'section') {
                    return;
                }
                values[field.fieldName] = field.defaultValue !== undefined ? field.defaultValue : null;
            });
        });
        return values;
    }, []);

    const onMoveToPage = (screen_index: number) => {
        setCurrentScreenIndex(screen_index);
        navigate('#' + screen_index, { state: { current_screen_index: screen_index } } );
    }

    const onValidate = (values: FormikValues) => {
        return validate(config, values, t);
    };

    const onSubmit = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
        actions.setSubmitting(true);
        // Enviamos los datos por POST, y si todo va bien, pasamos a la siguiente pantalla
        try {
            const reply = await sendForm(config,values);
            setSubmitError(null);
            navigate('/3/' + (process.env.PRIV ? 'priv': 'front') + '/resultados?signature=' + reply.signature);
        } catch(e) {
            setSubmitError(e.message);
        } finally {
            actions.setSubmitting(false);
        }
    };
    
    return <div className='dynamic-form'>
        <h1>{t(screen.title)}</h1>
        {screen.subtitle && <h2>{screen.subtitle}</h2>} 
        <Formik initialValues={{
            ...initialValues,
            ...previous_values
        }} 
            validate={onValidate}
            onSubmit={onSubmit}>
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <div>
                            <DynamicFormSaver config={config} />
                            <FormikScreen screen={screen} />
                            {submit_error && <div className='alert alert-danger'>{submit_error}</div>}
                            <div style={{height: '80px', position:'relative', textAlign:'center'}}>
                                {hasNext ?
                                <button 
                                    className='btn btn-primary btn-lg'
                                    onClick={() => onMoveToPage(current_screen_index + 1)}>{t('Siguiente')}</button>
                                :
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <FormikSubmitButton />
                                </div>
                                }
                                {hasPrevious && <button 
                                    className='btn btn-secondary'
                                    style={{'position': 'absolute', 'left': '0px', 'top': '0px'}}
                                    onClick={() => onMoveToPage(current_screen_index - 1)}>{t('Anterior')}</button>}
                            </div>
                        </div>
                    </form>
                )}
        </Formik>
    </div>
}

function validate( config: IForm, values: FormikValues, t: TFunction ) {

    const errors: Record<string,string> = {};
    for (const screen of config.screens) {
        screen.items.forEach(field => {
            if( field.type == 'section') {
                return;
            }
            if( field.required && !values[field.fieldName] ) {
                errors[field.fieldName] = t('Campo requerido');
            }
            if( field.type == 'email' && values[field.fieldName]) {
                // Validate as email
                const email = values[field.fieldName];
                const re = /\S+@\S+\.\S+/;
                if( !re.test(email) ) {
                    errors[field.fieldName] = t('Formato de email incorrecto');
                }
            }
            if( field.type == 'phone' && values[field.fieldName]) {
                // Validate as phone, i.e. +34 111 111 111, or 111111111, or 0034111111111
                const phone = values[field.fieldName];
                const re = /^(\+34|0034|34)?[ -]*(6|7|8|9)[ -]*([0-9][ -]*){8}$/;
                if( !re.test(phone) ) {
                    errors[field.fieldName] = t('Formato de teléfono incorrecto');
                }
            }
        });
    }
    console.log("Validate result", values, errors);
    return errors;
}