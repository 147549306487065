import React, { useEffect } from 'react';
import { IForm, IFormItem } from '../../globals/types';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useFormikContext } from 'formik';

/**
 * Este componente supervisa los cambios de estado de Formik y guarda en localStorage aquellos campos con remember: true
 */
export default function DynamicFormSaver({
    config
}: {
    config: IForm
}) {
    const [local_state, saveLocalState] = useLocalStorage<Record<string,any>>('dynamic_form', {});
    const context = useFormikContext();
    useEffect(() => {
        const values = context.values as Record<string,any>;
        let new_local_state : Record<string,any> = { ...local_state };
        config.screens.forEach(screen => {
            screen.items.forEach(field => {
                if( field.type == 'section') {
                    field.items.forEach(subfield => {
                        if( subfield.type !='section' && subfield.remember ) {
                            new_local_state[subfield.fieldName] = values[subfield.fieldName];
                        }
                    });
                    return;
                }
                if( field.remember ) {
                    new_local_state[field.fieldName] = values[field.fieldName];
                }
            });
        });
        saveLocalState(new_local_state);
    }, [context.values]);
    return <></>;
}