import React from 'react';
import { IFormItem } from '../../globals/types';
import FormikItem_Radio from './FormikItem_Radio';
import FormikItem_Dropdown from './FormikItem_Dropdown';
import FormikItem_Boolean from './FormikItem_Boolean';
import FormikItem_Section from './FormikItem_Section';
import FormikItem_ZipCode from './FormikItem_ZipCode';
import FormikItem_SingleLineText from './FormikItem_SingleLineText';
import FormikItem_LOPD from './FormikItem_LOPD';

type MyProps = {
    item: IFormItem
}

export default function FormikItem({
    item
}: MyProps) : JSX.Element {
    if( item.type === 'radio' ) {
        return <FormikItem_Radio config={item} />;
    } else if( item.type == 'dropdown' ) {
        return <FormikItem_Dropdown config={item} />;
    } else if( item.type == 'boolean' )  {
        return <FormikItem_Boolean config={item} />;
    } else if( item.type == 'section') {
        return <FormikItem_Section config={item} />;
    } else if( item.type == 'zip_code' ) {
        return <FormikItem_ZipCode config={item} />;
    } else if( item.type == 'single_line_text' ) {
        return <FormikItem_SingleLineText config={item} type='text' />;
    } else if( item.type == 'email' ) {
        return <FormikItem_SingleLineText config={item} type='email' />;
    } else if( item.type == 'phone' ) {
        return <FormikItem_SingleLineText config={item} type='tel' />;
    } else if( item.type == 'lopd' ) {
        return <FormikItem_LOPD config={item} />;
    }

    return <div><pre>ITEM{JSON.stringify(item, null, 4)}</pre></div>;
}