import i18next, { InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const options : InitOptions = {
  resources: {
    "es-CA": {
      translations: { /* Castellano/catalán */
        'Cargando...': 'Carregant...',
        'He leído y doy mi consentimiento para el tratamiento de mis datos personales': 'He llegit i dono el meu consentiment per al tractament de les meves dades personals',
        'Enviar solicitud': 'Enviar sol·licitud',
        'Legitimación del tratamiento': 'Legitimació del tractament',
        'Su consentimiento para tratar sus datos personales': 'El seu consentiment per tractar les seves dades personals',
        'Destinatario de cesiones o transferencias': 'Destinatari de cessió o transferència',
        'No se realizaran cesiones o transferencias internacionales de datos personales': 'No es realitzaran cessió o transferència internacional de dades personals',
        'Derechos de las personas interesadas': 'Drets de les persones interessades',
        'Acceder, rectificar y suprimir sus datos, así como el ejercicio de otros derechos conforme a lo establecido en la información adicional':
          'Accedir, rectificar i suprimir les seves dades, així com l\'exercici d\'altres drets conforme al que s\'estableix en la informació addicional',
        'Información adicional': 'Informació addicional',
        'Puede consultar la información adicional y detallada sobre': 'Pot consultar la informació addicional i detallada sobre',
        'protección de datos': 'protecció de dades',
        Municipio: "Municipi",
        Comarca: "Comarca",
        'Datos del ciudadano': 'Dades del ciutadà',
        'Anterior': 'Anterior',
        'No encontrado': 'No trobat',
        'Información básica sobre protección de datos': 'Informació bàsica sobre protecció de dades',
        'Responsable del tratamiento': 'Responsable del tractament',
        'Colegio de Arquitectos de Cataluña "COAC"': 'Col·legi d\'Arquitectes de Catalunya "COAC"',
        'Finalidad del tratamiento': 'Finalitat del tractament',
        'Registro de los ciudadanos que solicitan servicios de arquitectos a través del COAC': 'Registre dels ciutadans que sol·liciten serveis d\'arquitectes a través del COAC',

        'de': 'de',
        'Filas por página': 'Files per pàgina',
        'Nro. colegiado': 'Nro. col·legiat',
        'Nombre': 'Nom',
        'Apellidos': 'Cognoms',
        'Población': 'Població',
        'Cód. postal': 'Cód. postal',
        'Teléfono': 'Telèfon',
        'Correo electrónico': 'Correu electrònic',
        'Formación adicional': 'Formació addicional',
        'Empresa': 'Empresa',
        'Enlace web (empresa)': 'Enllaç web (empresa)',
        'Siguiente': 'Següent',
        '¿Qué servicio necesitas?': 'Quin servei necessites?',
        'Selecciona tu servicio': 'Selecciona el teu servei',
        'Rehab. Energética Next Gen.': 'Rehab. Energètica Next Gen.',
        'AGENTE REHABILITADOR (realización de los trámites y gestiones necesarios para la solicitud de subvenciones)':
          'AGENT REHABILITADOR (realització dels tràmits i gestions necessaris per a la sol·licitud de subvencions)',
        'NO HACE EL PROYECTO': 'NO FA EL PROJECTE',
        'Tramitación de la Subvención': 'Tramitació de la Subvenció',
        'PROYECTO Y SUBVENCIÓN EDIFICIOS': 'PROJECTE I SUBVENCIÓ EDIFICIS',
        'PROYECTO Y SUBVENCIÓN VIVIENDAS': 'PROJECTE I SUBVENCIÓ HABITATGES',
        'Proyectos y obras de rehabilitación energética a nivel EDIFICIO': 'Projectes i obres de rehabilitació energètica a nivell EDIFICI',
        'Proyectos y obras de rehabilitación energética a nivel VIVIENDA': 'Projectes i obres de rehabilitació energètica a nivell HABITATGE',
        'Principalmente': 'Principalment',
        'Programa 3': 'Programa 3',
        'Programa 4': 'Programa 4',
        'de los Next Generation': 'dels Next Generation',
        'actuaciones en fachadas, protecciones solares y cubiertas, instalaciones del edificio y energias renovables':
          'actuacions a façanes, proteccions solars i cobertes, instal·lacions de l\'edifici i energies renovables',
        'Rehabilitación energética pisos.': 'Rehabilitació energètica pisos.',
        'Cambio ventanas.': 'Canvi finestres.',
        'Calderas, instalaciones interiores.': 'Calderes, instal·lacions interiors.',
        'Climatización y refrigeración.': 'Climatització i refrigeració.',

        '2. Proyectos y Dirección de obra': '2. Projectes i Direcció d\'obra',
        'Proyectos y Dirección de obra': 'Projectes i Direcció d\'obra',
        'Proyecto de edificación': 'Projecte d\'edificació',
        'Vivienda': 'Habitatge',
        'Piscina o jardín': 'Piscina o jardí',
        'Legalizaciones': 'Legalitzacions',
        'Proyecto Cambio de uso': 'Projecte Canvi d\'ús',
        'Proyecto reformas': 'Projecte reformes',
        'Accesibilidad': 'Accessibilitat',
        'Selecciona una opción o deja en blanco': 'Selecciona una opció o deixa en blanc',
        'Rehabilitación y mant. edificios': 'Rehabilitació i mant. edificis',
        'Rehabilitación de viviendas': 'Rehabilitació d\'habitatges',
        'Rehabilitación de fachadas': 'Rehabilitació de façanes',
        'Patologías y grietas': 'Patologies i esquerdes',
        'Humedades': 'Humitats',
        'Colocación de un ascensor': 'Col·locació d\'un ascensor',
        'Certificado de solidez': 'Certificat de solidesa',
        'Aluminosis': 'Aluminosi',
        'Informes y certificados': 'Informes i certificats',
        'Certificado energético': 'Certificat energètic',
        'Cédula de habitabilidad': 'Cèdula d\'habitabilitat',
        'Licencia de actividades': 'Llicència d\'activitats',
        'Licencia de actividades de un local': 'Llicència d\'activitats d\'un local',
        'Licencia de rótulo de local': 'Llicència de rètol de local',
        'Emplazamiento de la obra': 'Emplaçament de l\'obra',
        'Código postal': 'Codi postal',
        'Nombre y Apellido': 'Nom i Cognom',
        'Teléfono (NO OBLIGATORIO)': 'Telèfon (NO OBLIGATORI)',
        'Adjuntamos los datos de los arquitectos colegiados que pueden atender su solicitud de servicios {{serv}} en la comarca {{comarca}}':
          'Adjuntem les dades dels arquitectes col·legiats que poden atendre la seva sol·licitud de serveis {{serv}} a la comarca {{comarca}}',
        'ITE, Inspección Técnica de Edificio':'ITE, Inspecció Tècnica d\'Edifici',
        'Parcelaciones y Urbanismo':'Parcelacions i Urbanisme',
        'Patrimonio':'Patrimoni',
        'Interiorismo':'Interiorisme',
        'Mediación':'Mediació',
        'Rutas arquitectónicas':'Rutes arquitectòniques',
        'Talleres de arquitectura en escuelas':'Tallers d\'arquitectura a escoles',
        'Paisajismo':'Paisatgisme'

      }
    },
    "es-ES": {  /* Castellano/castellano */
      translations: {
        'Adjuntamos los datos de los arquitectos colegiados que pueden atender su solicitud de servicios {{serv}} en la comarca {{comarca}}':
          'Adjuntamos los datos de los arquitectos colegiados que pueden atender su solicitud de servicios {{serv}} en la comarca {{comarca}}',
        'Emplazamiento de la obra': 'Emplazamiento de la obra',
        'Código postal': 'Código postal',
        'Nombre y Apellido': 'Nombre y Apellido',
        'Correo electrónico': 'Correo electrónico',
        'Teléfono (NO OBLIGATORIO)': 'Teléfono (NO OBLIGATORIO)',

        'Informes y certificados': 'Informes y certificados',
        'Certificado energético': 'Certificado energético',
        'Cédula de habitabilidad': 'Cédula de habitabilidad',
        'Licencia de actividades': 'Licencia de actividades',
        'Licencia de actividades de un local': 'Licencia de actividades de un local',
        'Licencia de rótulo de local': 'Licencia de rótulo de local',

        'Selecciona una opción o deja en blanco': 'Selecciona una opción o deja en blanco',
        'Rehabilitación y mant. edificios': ' Rehabilitación y mant. edificios',
        'Rehabilitación de viviendas': 'Rehabilitación de viviendas',
        'Rehabilitación de fachadas': 'Rehabilitación de fachadas',
        'Patologías y grietas': 'Patologías y grietas',
        'Humedades': 'Humedades',
        'Colocación de un ascensor': 'Colocación de un ascensor',
        'Certificado de solidez': 'Certificado de solidez',
        'Aluminosis': 'Aluminosis',

        '2. Proyectos y Dirección de obra': '2. Proyectos y Dirección de obra',
        'Proyectos y Dirección de obra': 'Proyectos y Dirección de obra',
        'Proyecto de edificación': 'Proyecto de edificación',
        'Vivienda': 'Vivienda',
        'Piscina o jardín': 'Piscina o jardín',
        'Legalizaciones': 'Legalizaciones',
        'Proyecto Cambio de uso': 'Proyecto Cambio de uso',
        'Proyecto reformas': 'Proyecto reformas',
        'Accesibilidad': 'Accesibilidad',

        'Siguiente': 'Siguiente',
        '¿Qué servicio necesitas?': '¿Qué servicio necesitas?',
        'Selecciona tu servicio': 'Selecciona tu servicio',
        'Rehab. Energética Next Gen.': 'Rehab. Energética Next Gen.',
        'AGENTE REHABILITADOR (realización de los trámites y gestiones necesarios para la solicitud de subvenciones)': 
          'AGENTE REHABILITADOR (realización de los trámites y gestiones necesarios para la solicitud de subvenciones)',
        'NO HACE EL PROYECTO': 'NO HACE EL PROYECTO',
        'Tramitación de la Subvención': 'Tramitación de la Subvención',
        'PROYECTO Y SUBVENCIÓN EDIFICIOS': 'PROYECTO Y SUBVENCIÓN EDIFICIOS',
        'PROYECTO Y SUBVENCIÓN VIVIENDAS': 'PROYECTO Y SUBVENCIÓN VIVIENDAS',
        'Proyectos y obras de rehabilitación energética a nivel EDIFICIO': 'Proyectos y obras de rehabilitación energética a nivel EDIFICIO',
        'Proyectos y obras de rehabilitación energética a nivel VIVIENDA': 'Proyectos y obras de rehabilitación energética a nivel VIVIENDA',
        'Principalmente': 'Principalmente',
        'Programa 3': 'Programa 3',
        'Programa 4': 'Programa 4',
        'de los Next Generation': 'de los Next Generation',
        'actuaciones tipo SATE aislamiento de envolvente y cubierta, instalaciones del edificio y renovables': 
          'actuaciones tipo SATE aislamiento de envolvente y cubierta, instalaciones del edificio y renovables',
        'Rehabilitación energética pisos.': 'Rehabilitación energética pisos.',
        'Cambio ventanas.': 'Cambio ventanas.',
        'Calderas, instalaciones interiores.': 'Calderas, instalaciones interiores.',
        'Climatización y refrigeración.': 'Climatización y refrigeración.',

        'de': 'de',
        'Filas por página': 'Filas por página',
        'Nro. colegiado': 'Nro. colegiado',
        'Nombre': 'Nombre',
        'Apellidos': 'Apellidos',
        'Población': 'Población',
        'Cód. postal': 'Cód. postal',
        'Teléfono': 'Teléfono',
        'Formación adicional': 'Formación adicional',
        'Empresa': 'Empresa',
        'Enlace web (empresa)': 'Enlace web (empresa)',

        'Cargando...': 'Cargando...',
        'He leído y doy mi consentimiento para el tratamiento de mis datos personales': 'He leído y doy mi consentimiento para el tratamiento de mis datos personales',
        'Enviar solicitud': 'Enviar solicitud',
        'Legitimación del tratamiento': 'Legitimación del tratamiento',
        'Su consentimiento para tratar sus datos personales': 'Su consentimiento para tratar sus datos personales',
        'Destinatario de cesiones o transferencias': 'Destinatario de cesiones o transferencias',
        'No se realizaran cesiones o transferencias internacionales de datos personales': 'No se realizaran cesiones o transferencias internacionales de datos personales',
        'Derechos de las personas interesadas': 'Derechos de las personas interesadas',
        'Acceder, rectificar y suprimir sus datos, así como el ejercicio de otros derechos conforme a lo establecido en la información adicional':
          'Acceder, rectificar y suprimir sus datos, así como el ejercicio de otros derechos conforme a lo establecido en la información adicional',
        'Información adicional': 'Información adicional',
        'Puede consultar la información adicional y detallada sobre': 'Puede consultar la información adicional y detallada sobre',
        'protección de datos': 'protección de datos',
        Municipio: "Municipio",
        Comarca: "Comarca",
        'Datos del ciudadano': 'Datos del ciudadano',
        'Anterior': 'Anterior',
        'No encontrado': 'No encontrado',
        'Información básica sobre protección de datos': 'Información básica sobre protección de datos',
        'Responsable del tratamiento': 'Responsable del tratamiento',
        'Colegio de Arquitectos de Cataluña "COAC"': 'Colegio de Arquitectos de Cataluña "COAC"',
        'Finalidad del tratamiento': 'Finalidad del tratamiento',
        'Registro de los ciudadanos que solicitan servicios de arquitectos a través del COAC': 'Registro de los ciudadanos que solicitan servicios de arquitectos a través del COAC',
        'Paisajismo':'Paisatgisme',
      }
    }
  },
  fallbackLng: false, //"en", //"es-ES",
  debug: true,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },
  react: {
//    wait: true
  }
};

i18next
.use(LanguageDetector)
.use(initReactI18next)
.init(options);

export default i18next;