import React from 'react';
import { IFormItem_LOPD } from '../../globals/types';
import { useField } from 'formik';
import FormCheck from 'react-bootstrap/FormCheck';
import { useTranslation } from 'react-i18next';

type MyProps = {
    config: IFormItem_LOPD
};

export default function FormikItem_LOPD({
    config
}: MyProps ) {
    const {t} = useTranslation();
    const [field, meta, helpers] = useField(config.fieldName);

    return <div className='lopd'>
        <div className='titulo'>{t('Información básica sobre protección de datos')}</div>
        <div className='tabla-lopd'>
            <div className='left'>{t('Responsable del tratamiento')}</div>
            <div className='right'>{t('Colegio de Arquitectos de Cataluña "COAC"')}</div>

            <div className='left'>{t('Finalidad del tratamiento')}</div>
            <div className='right'>{t('Registro de los ciudadanos que solicitan servicios de arquitectos a través del COAC')}</div>

            <div className='left'>{t('Legitimación del tratamiento')}</div>
            <div className='right'>{t('Su consentimiento para tratar sus datos personales')}</div>

            <div className='left'>{t('Destinatario de cesiones o transferencias')}</div>
            <div className='right'>{t('No se realizaran cesiones o transferencias internacionales de datos personales')}</div>

            <div className='left'>{t('Derechos de las personas interesadas')}</div>
            <div className='right'>{t('Acceder, rectificar y suprimir sus datos, así como el ejercicio de otros derechos conforme a lo establecido en la información adicional')}</div>

            <div className='left'>{t('Información adicional')}</div>
            <div className='right'>{t('Puede consultar la información adicional y detallada sobre')}&nbsp;
                <a href='https://www.arquitectes.cat/ca/politica-de-privacitat' target='_blank'>{t('protección de datos')}</a></div>
        </div>
        <div className='agreement-container'>
            <div className='agreement'>
                <FormCheck>
                    <FormCheck.Input type="checkbox" name={config.fieldName}
                        style={{width: '30px', height: '30px'}}
                        isInvalid={meta.touched && ( (!!meta.error) || !field.value )}
                        checked={field.value || false}
                        onBlur={field.onBlur}
                        onChange={(e)=>{
                            helpers.setValue(e.target.checked);
                        }}
                        />
                    <FormCheck.Label className='mt-2 px-3'>{t('He leído y doy mi consentimiento para el tratamiento de mis datos personales')}</FormCheck.Label>
                </FormCheck>
                
            </div>
        </div>
    </div>;
}
