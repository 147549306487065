import React from 'react';

export default function Footer() {
    return (<footer id="footer">
    <div className="container-fluid">
        <div className="row">
            <div className="col-md-4">


                <h6>Informació legal</h6>
                <ul className="list-unstyled">
                    <li><a href="#">Avís legal</a></li>
                    <li><a href="#">Política de cookies</a></li>
                    <li><a href="#">Política de privacitat</a></li>
                </ul>
            </div>
            <div className="col-md-4">
                <span>
                    Col·legi d'Arquitectes de Catalunya<br />
                    copyright@coac 2022
                </span>
            </div>
            <div className="col-md-4">
                <h6>COAC</h6>
                <p>
                    Plaça Nova, 5<br />
                    08002 Barcelona<br />
                    —<br />
                    T 93 306 78 03<br />
                    <a href="3">coac@coac.cat</a>
                </p>
            </div>
        </div>
    </div>
</footer>);
}