import React from 'react';
import { IFormItem_Email, IFormItem_Phone, IFormItem_SingleLineText } from '../../globals/types';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

type MyProps = {
    config: IFormItem_SingleLineText|IFormItem_Email|IFormItem_Phone,
    type: 'text'|'email'|'tel'
};

export default function FormikItem_SingleLineText({
    config,
    type
}: MyProps ) {
    const {t} = useTranslation();
    const [field, meta, helpers] = useField(config.fieldName);

    return <div className='formik-row'>
        <div className="column1">
            {typeof config.title == 'string' ? t(config.title) : config.title}
            {config.required && <span className='text-danger'> *</span>}
        </div>
        <div className='column2 d-flex'>
            <Form.Control value={field.value||''}
                name={config.fieldName}
                className={config.type}
                isInvalid={meta.touched && !!meta.error}
                type={type}
                onBlur={field.onBlur}
                required={config.required}
                onChange={(e)=>{
                    helpers.setValue(e.target.value);
                    helpers.setTouched(true);
                }}
                />
        </div>
    </div>;
}