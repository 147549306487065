import React from 'react';
import { IArquitectData } from '../../hooks/useResultsFeed';
import { useTranslation } from 'react-i18next';
import prev from '../../img/prev.svg';
import { TableColumn } from 'react-data-table-component';
import './css/ResultadosComoLista.css';


type MyProps = {
    data: IArquitectData[],
    result_count: number,
    page: number,
    per_page: number,
    onSetPage: (page: number) => void,
    columns: TableColumn<IArquitectData>[]
};

function findColumnByCode( columns: TableColumn<IArquitectData>[], code: string ) {
    return columns.find( (col) => (col as any).code === code );
}

/**
 * Este componente se muestra sólo cuando el ancho de la pantalla no es suficiente para mostrar la tabla de resultados.
 */
export default function ResultadosComoLista({
    data,
    result_count,
    page,
    per_page,
    onSetPage,
    columns
}: MyProps) {
    const {t} = useTranslation();
    const page_count = Math.ceil(result_count / per_page);
    return <div>
        {data.map( (row: IArquitectData, i) => {
            const email_column = findColumnByCode(columns, 'email');
            const empresas_column = findColumnByCode(columns, 'empresas');
            const formaciones_column = findColumnByCode(columns, 'formaciones');
            const link_empresas_column = findColumnByCode(columns, 'link_empresas');

            return <div key={row.num_colegiado} className='card mb-2'>
                <div className='card-body tarjeta'>
                    <div className='num-colegiado'>{row.num_colegiado}</div>
                    <div className='nombre-completo'>{row.apellidos}, {row.nombre}</div>
                    <div>{row.ciudad}</div>
                    <div>{row.phone}</div>
                    {row.email && <div>{email_column?.cell(row, i, email_column, 'email')}</div>}
                    {row.empresas && row.empresas.length ? 
                        <div className='empresas'>
                            <b>{t('Empresas')}</b><br/>
                            {empresas_column?.cell(row, i, empresas_column, 'empresas')}
                        </div> : <></>
                    }
                    {row.empresas && row.empresas.filter( e => e.web ).length ? 
                        <div className='link-empresas'>
                            <b>{t('Links')}</b><br/>
                            {link_empresas_column?.cell(row, i, link_empresas_column, 'link_empresas')}
                        </div> : <></>
                    }
                    {row.formaciones && row.formaciones.length ? 
                        <div className='formaciones'>
                            <b>{t('Formaciones')}</b><br/>
                            {formaciones_column?.cell(row, i, formaciones_column, 'formaciones')}
                        </div> : <></>
                    }
                </div>
                </div>;
        })}
        {t('Página')} {page} {t('de')} {page_count} ({result_count} {t('resultados')})
        {page > 1 && 
            <button className='btn btn-light mx-2' onClick={() => onSetPage(page-1)} style={{borderRadius: 20 }}>
                <img src={prev} width={16} title={t('Anterior')} />
                </button>
        }
        {page < page_count && 
            <button className='btn btn-light mx-2' onClick={() => onSetPage(page+1)} style={{borderRadius: 20 }}>
                <img src={prev} width={16} title={t('Siguiente')} /* Rotated 180 deg */ style={{transform: 'rotateZ(180deg)'}} />
            </button>
        }
    </div>
}