import React from 'react';
import { IFormItem_Dropdown, IFormItem_Radio } from '../../globals/types';
import { useField } from 'formik';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

type MyProps = {
    config: IFormItem_Dropdown
};

export default function FormikItem_Dropdown({
    config
}: MyProps ) {
    const {t} = useTranslation();
    const [field, meta, helpers] = useField(config.fieldName);

    const options = config.options.map(option => {
        return {
            value: option.optionKey,
            label: typeof option.title == 'string' ? t(option.title) : option.title
        };
    });

    return <div className='formik-row'>
        <div className="column1">{typeof config.title == 'string' ? t(config.title) : config.title}</div>
        <Select options={options} className='bootstrap-select' onChange={(e) => {
            helpers.setValue(e.value);
        }} value={options.find( opt => opt.value == field.value)}
            placeholder={t('Selecciona una opción o deja en blanco')}
         />
    </div>;
}