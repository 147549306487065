import {IForm} from "../globals/types";
import {FormikValues} from "formik";

// Envía unos datos por POST a process.env.BACKEND + un target

export type ISendFormReply = {
    signature: string
}

export default async function sendForm( config: IForm, values: FormikValues ) {
    const url = (process.env.BACKEND||'') + config.target;
    const data = new FormData();
    data.append('data', JSON.stringify(values));
    const reply = await fetch(url, {
        method: 'POST',
        body: data
    });
    const json = await reply.json() as ISendFormReply;
    if( !json.signature ) {
        throw new Error('No se ha recibido una firma válida');
    }
    return json;
}