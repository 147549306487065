import { useFormik, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';



export default function FormikSubmitButton() {
    const {t} = useTranslation();
    const formik = useFormikContext();

    return <button 
                    type='submit' 
                    disabled={formik.isSubmitting || !formik.isValid ||!formik.dirty}
                    className='btn btn-primary w-50' 
                    style={{'float': 'right'}}>
                        {t('Enviar solicitud')}
                </button>;
}