import React from 'react';
import { useTranslation } from 'react-i18next';
import { IResultsFeedReply } from '../../hooks/useResultsFeed';
import form_find_architect from '../../config/form_find_architect';

type MyProps = {
    data: IResultsFeedReply,
    comarca: string
}

export default function TituloResultados({
    data,
    comarca
}: MyProps) {
    const {t} = useTranslation();
    const {screens} = form_find_architect(t);
    let descriptions : string[] = [];
    for( const section of screens ) {
        for( const item of section.items ) {
            if( item.type === 'section' ) {
            } else {
                console.log(data);
                const value = (data.busqueda as Record<string,any>)[item.fieldName];
                if( value !== undefined && value !== false && value !== null && item.service_label ) {
                    descriptions.push( item.service_label );
                }
            }
        }
    }
    // Unir por coma, la última con 'y'
    let descriptions_str : string;
    if( descriptions.length > 1 ) {
        descriptions_str = descriptions.slice(0,descriptions.length-1).join(', ') + ' y ' + descriptions[descriptions.length-1];
    } else {
        descriptions_str = descriptions[0];
    }
    return <div>
        {t('Adjuntamos los datos de los arquitectos colegiados que pueden atender su solicitud de servicios {{serv}} en la comarca {{comarca}}',
        {
            serv: descriptions_str,
            comarca
        })}&nbsp;
    </div>
}