import React from 'react';
import { IFormScreen } from '../../globals/types';
import FormikItem from './FormikItem';

type MyProps = {
    screen: IFormScreen,
}

export default function FormikScreen({
    screen
}: MyProps) : JSX.Element {
    return <div>
        {screen.items.map((item,i) => {
            return <FormikItem item={item} key={i} />;
        })}
    </div>;
}