import React, { useEffect, useState } from 'react';
import useResultsFeed, { IArquitectData } from '../../hooks/useResultsFeed';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import './css/TablaResultados.css';
import ResultadosComoLista from './ResultadosComoLista';
import FullResultsDownloadButton from './FullResultsDownloadButton';
import TituloResultados from './TituloResultados';

export default function TablaResultados() {
    const {t} = useTranslation();
    const [page,setPage] = React.useState(1);
    const [per_page,setRowsPerPage] = React.useState(10);
    const [sort_field, setSortField] = React.useState<string|undefined>(undefined);
    const [sort_direction, setSortDirection] = React.useState<SortOrder>('asc' as SortOrder);
    // Get 'signature' from query params
    const signature = React.useMemo(() => {
        return new URLSearchParams(window.location.search).get('signature');
    }, []);

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const {isLoading,error,data} = useResultsFeed( signature, page, per_page, sort_field, sort_direction );

    const columns = React.useMemo(() => {
        return [
            {
                name: t('Nro. colegiado'),
                width: '120px',
                cell: (row: IArquitectData) => <div>{row.num_colegiado}</div>
            },
            {
                name: t('Nombre'),
                width: '150px',
                cell: (row: IArquitectData) => <div>{row.nombre}</div>
            },
            {
                name: t('Apellidos'),
                code: 'cognom',
                cell: (row: IArquitectData) => <div>{row.apellidos}</div>,
                sortable: true
            },
            {
                name: t('Población'),
                width: '150px',
                cell: (row: IArquitectData) => <div>{row.ciudad}</div>
            },
            {
                name: t('Cód. postal'),
                code: 'cp',
                width: '120px',
                cell: (row: IArquitectData) => <div>{row.zip_code}</div>,
                sortable: true
            },
            {
                name: t('Teléfono'),
                width: '180px',
                cell: (row: IArquitectData) => <div>{row.phone}</div>
            },
            {
                name: t('Correo electrónico'),
                code: 'email',
                cell: (row: IArquitectData) => <div><a href={`mailto:${row.email}`}>{row.email}</a></div>,
            },
            {
                name: t('Formación adicional'),
                code: 'formaciones',
                cell: (row: IArquitectData) => <div>{row.formaciones.map(f => <div key={f.titulo + f.inicio}>{f.titulo}</div>)}</div>
            },
            {
                name: t('Empresa'),
                code: 'empresas',
                cell: (row: IArquitectData) => <div>{row.empresas.slice(0,1).map(f => <div key={f.empresa + f.inicio}>{f.empresa}</div>)}</div>
            },
            {
                name: t('Enlace web (empresa)'),
                code: 'link_empresas',
                cell: (row: IArquitectData) => <div>{row.empresas.slice(0,1).map(f => <div key={f.empresa + f.inicio}>{
                    f.web
                    ? <a href={f.web} target='_blank'>{f.web}</a>
                    : ''
                }</div>)}</div>
            }
        ] as TableColumn<IArquitectData>[];
    }, [t]);

    const onChangeRowsPerPage = (pp: number) => {
        if( per_page != pp ) {
            console.log("Set rows per page " + pp);
            setPage(1);
            setRowsPerPage(pp);
        }
    }

    const onSort = (selectedColumn: TableColumn<IArquitectData>, sortDirection: SortOrder, sortedRows: IArquitectData[]) => {
        if(selectedColumn) {
            setSortField((selectedColumn as any).code as string);
            setSortDirection(sortDirection);
        }
    }

    if( isLoading ) {
        return <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
            <ClipLoader color='#36d7b7' size={300} /> 
            </div>;
    } else if( error ) {
        return <div className='alert alert-danger'>{t('Ha ocurrido un error obteniendo los resultados')}</div>;
    }

    return <div>
        <center><h3>
            {isSmallScreen ? 
                t('Resultados') :
                <TituloResultados data={data} comarca={data.request_zip_data.comarca} />
            }
        </h3></center>
        {isSmallScreen 
            ? <ResultadosComoLista 
                data={data.rows}
                page={page}
                per_page={per_page}
                result_count={data.count}
                onSetPage={setPage}
                columns={columns}
            />
            : <DataTable<IArquitectData>
                fixedHeader={true}
                fixedHeaderScrollHeight='640px'
                columns={columns}
                paginationDefaultPage={page}
                pagination={true}
                paginationServer={true}
                paginationTotalRows={data.count}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={setPage}
                paginationRowsPerPageOptions={[10]}
                onSort={onSort}
                sortServer={true}
                paginationComponentOptions={{
                    rowsPerPageText: t('Filas por página'),
                    rangeSeparatorText: t('de'),
                }}
                data={data.rows} />
            }
        {process.env.PRIV &&
            <FullResultsDownloadButton signature={signature} />
        }
    </div>;
}