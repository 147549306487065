import React from 'react';
import {createRoot} from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './app/App';
import './index.css';

// Render Layout in a new React App with createRoot()

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <QueryClientProvider client={new QueryClient()}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>
);

declare var module : {
    hot : {
      accept(path?:string, callback?:() => void): void;
    };
};

if(module.hot) {
    module.hot.accept();
}
