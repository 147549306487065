import React from 'react';

import image from '../img/elements/logo-coac-arquitectes.png';
import { useTranslation } from 'react-i18next';

export default function Header() {
    const {i18n} = useTranslation();


    const onChangeLanguage = (e: React.MouseEvent, lang: string) => {
        e.preventDefault();
        i18n.changeLanguage(lang);
    };
    return (<header id="header">
    <div className="container-fluid">
        <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr 100px'}} className='m-3'>
            <div>
                <div id="logo">
                    <a href="#">
                        <img width="auto" height="120" className="img-responsive" src={image} alt="" />
                    </a>
                </div>				
            </div>

            <div>
            </div>

            {/* Selector de idioma ca / es */}
            <div >
                <div style={{display: 'flex'}}>
                    <a onClick={(e) => onChangeLanguage(e, 'es-ES')} href='#' title='Español' style={{textDecoration: 'none' }}>
                        ES
                    </a>
                    <div>&nbsp;/&nbsp;</div>
                    <a onClick={(e) => onChangeLanguage(e, 'es-CA')} href="#" title='Català' style={{textDecoration: 'none' }}>
                        CA
                    </a>
                </div>
            </div>

        </div>
    </div>
</header>);
}